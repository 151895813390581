<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container p-3">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body stretch class="bg-primary full-height d-flex wrap register-side text-center align-items-center">
                    <div class="full-width align-self-start">
                        <div class="full-width navbar-brand mb-4 mt-4">
                            <img class="navbar-brand-full" :src="appLogo()" height="100" alt="Dynamic Studio Logo">
                        </div>
                        <q-separator class="m-0" dark />
                    </div>
                    <div class="full-width pb-4 align-self-end text-white">
                        <h2 class="mt-4 mb-4 text-h4">{{ translate('welcome') }}</h2>
                    </div>
                </q-card>
            </b-col>
            <b-col md="6" class="p-0 bg-white">
                 <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang pl-3 pr-1 mb-4" :style="{position: 'relative', marginTop: '-1rem'}">
                        <div class="text-muted font-weight-bold">{{ translate('selected_language') }}</div>
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <b-form @submit.prevent="forgotPassword">
                        <h1 class="text-h4 text-uppercase text-center font-weight-bold text-primary">{{ translate('title_resend_activation_link') }}</h1>
                        <p class="text-center text-muted">{{ translate('resend_activation_email_description') }}</p>

                        <b-input-group class="mb-3">
                            <q-input light square dense outlined :error-message="translate('email_is_required')" no-error-icon :error="submitted && $v.user.email.$error" color="primary" type="text" v-model="user.email" :label="translate('email')">
                                <template v-slot:append>
                                    <q-icon name="icon-envelope" />
                                </template>
                            </q-input>
                            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                                <span v-if="!$v.user.email.email">Email is invalid</span>
                            </div>
                        </b-input-group>

                        <b-input-group v-if="msg">
                            <q-item :class="success ? 'alert-success border-top-green' : 'alert-danger border-top-red'" class="alert">
                                <q-item-section>{{ msg }}</q-item-section>
                            </q-item>
                        </b-input-group>
                        
                        <div class="text-center">
                            <q-btn class="mt-2 icon-right-leftspace" icon-right="send" color="success" square no-caps type="submit">{{ translate('send') }}</q-btn>
                        </div>
                    </b-form>
                 </q-card>
            </b-col>
        </b-row>
    </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import { Header as AppHeader} from '@coreui/vue'
import { required, email, minLength, sameAs } from "vuelidate/lib/validators"
import LangNavbarAuth from './LangNavbarAuth'

export default {
    name: 'ResendActivationEmail',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    data: function () {
        return {
            user: {
                email: null
            },
            msg: null,
            submitted: false,
            selected_language: 'ro',
            success: false,
        }
    },
    mounted: function () {
        eventBus.$on('current_language', (lang) => {
            this.setSelectedLanguage(lang)
        })
    },
    validations: {
        user: {
            email: { required, email },
        }
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return 'background-image: url(' + this.random_background + ');'
        },
    },
    methods: {
        setSelectedLanguage: function (lang) {
            this.selected_language = lang
        },
        forgotPassword: function() {
            this.submitted = true
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }
        
            if (this.selected_language) {
                this.user.lang = this.selected_language
            }
            axios.post(baseUrl + 'auth/resend-activation-email', this.user)
                .then(response => {
                    var message = ''
                    if (response.data.status) {
                        message = 'resend_activation_email_check_your_email'
                        this.success = true
                    } else {
                        message = "resend_activation_email_" + response.data.msg
                        this.success = false
                    }
                    this.msg = this.translate(message)
                })
                .catch(error => {
                var msg = error.response.data.msg;
                if (msg != undefined) {
                    this.msg = msg;
                }
            })
        }
    }
}
</script>
